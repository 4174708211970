import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdminJHApi from "../../utils/AdminJHApi"
import clsx from  'clsx';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import MarketingEvent from './MarketingEvent';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FIELD_TYPES, SYSTEMS, WEEK_DAYS_SHORT } from '../../utils/utils';
import JSData from '../JSData';
import JSTablePagination from '../JSTablePagination';
import SearchIcon from '@material-ui/icons/Search';
import { Fields } from '../Fields';
import MarketingTasks from './MarketingTasks';

const useStyles = makeStyles((theme) => ({
    cell: {
        border: "1px solid rgba(224, 224, 224, 1)",
        padding: 4
    },
    isToday: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    isNow: {
        /*backgroundColor: blue[100],*/
        scrollMarginTop: '50px'
    },
    publishPost: {
        padding: '8px 4px',
        border: '1px solid grey',
        borderRadius: 5
    },
    publishPostPlan: {
        opacity: 0.5
    },
    published:{
        background: green[200],
    },
    ready:{
        background: yellow[200],
    },
    cellOnList: {
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    userRow:{
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    problem: {
        background: red[300],
        /*'& $cell': {
            color:'white'
        }*/
    },
    selected:{
        background: blue[100],
    },
    selectedCanDelete:{
        background: purple[200],
        '&:hover': {
            background: 'white',
        }
    },
    hidden:{
        backgroundColor: grey[400]
    },
    canSelect:{
        cursor: 'pointer',
        /*'&:hover': {
            background: blue[100],
        }*/
    },
    canFill:{
        cursor: 'pointer',
        '&:hover': {
            background: purple[200],
        }
    }
}));

function Search(props) {
    const from = props.from.toISOString()
    const to = props.to.toISOString()

    const disabled = props.disabled || false

    const [newData, setNewData] = useState({});

    useEffect(() => {
        setNewData({from, to})
    }, [from, to]);

    const handleChangeField = e => {
        setNewData({...newData, [e.target.name]: e.target.value})
    };

    const handleSearch = e => {
        e.preventDefault();

        props.handleSearch(newData)
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const fields = [
        { name: 'from', label:'From', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'to', label:'To', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
    ]

    return (
        <>
            <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
            <IconButton onClick={handleSearch} style={{marginLeft:16}} color="primary" disabled={disabled}>
                <SearchIcon/>
            </IconButton>
        </>
    )
}

export default function MarketingCalendar(props) {
    const PUBLISH_TIMES = [7,8,9,10,11,12,13,14,15,16,17]
    const PUBLISH_MINUTES = [0,10,20,30,40,50]

    let defStartDate = new Date()
    defStartDate.setDate(defStartDate.getDate() - defStartDate.getDay())

    let defEndDate = new Date()
    defEndDate.setDate(defEndDate.getDate() - defEndDate.getDay()+6)

    const [startDate, setStartDate] = useState(defStartDate);
    const [endDate, setEndDate] = useState(defEndDate);
    const [dates, setDates] = useState();
    const [error, setError] = useState();
    const [events, setEvents] = useState([]);
    const [eventsPlan, setEventsPlan] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const [objectToEdit, setObjectToEdit] = useState();
    const [tasksDate, setTasksDate] = useState();

    const [view, setView] = useState('calendar');
    const [systemToShow, setSystemToShow] = useState('LINKEDIN');

    const [cells, setCells] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);

    const [fetch, setFetch] = useState(true);
    const [scroll, setScroll] = useState(true);

    const nowRef = useRef(null)

    const nowDate = new Date();
    const options = { timeZone: "Europe/Belgrade", hour: "2-digit", minute: "2-digit", hour12: false };
    const nowTime = new Intl.DateTimeFormat("en-GB", options).format(nowDate);

    const [nowHours, nowMinutes] = nowTime.split(":").map(Number);

    useEffect(() => {
        if (fetch && startDate && endDate && view){
            setDisabled(true)
            AdminJHApi.getMCalendar({startDate, endDate, view, page, system:systemToShow},(response)=>{
                /*setDates([...Array(7)].map((x, i) => {
                    let newDate = new Date(startDate)
                    newDate.setDate(newDate.getDate() + i)
                    return newDate
                }))*/
                
                setCount(response.count||0)
                setDates(response.dates||[])
                setEvents(response.events || [])
                setEventsPlan(response.eventsPlan || [])
                setCells(response.cells || [])
                setError()
                setDisabled(false)
                setFetch(false)

                if(scroll){
                    setTimeout(() => {
                        if (nowRef && nowRef.current) {
                            nowRef.current.scrollIntoView()
                        }
                        setScroll(false)
                    }, 300);
                }
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }

                setDisabled(false)
                setFetch(false)
            })
        }
    }, [startDate, endDate, view, fetch, page, scroll, systemToShow]);

    const handleChangeView = newView => {
        if (newView === 'data' && view !== 'data'){
            let monthAgo = new Date()
            monthAgo.setMonth(monthAgo.getMonth()-1)
            setFetch(true)
            setStartDate(monthAgo)
            setEndDate(new Date())
        } else if (newView !== 'data' && view === 'data') {
            setScroll(true)
            setFetch(true)
            setStartDate(defStartDate)
            setEndDate(defEndDate)
        }
        setView(newView)
    }

    const handleChangeSystemToShow = newSystem => {
        setSystemToShow(newSystem)
        if (view === 'calendar'){
            setTimeout(() => {
                if (nowRef && nowRef.current) {
                    nowRef.current.scrollIntoView()
                }
            }, 300);
        }
    }

    const handleSearch = search => {
        setFetch(true)
        setStartDate(new Date(search.from))
        setEndDate(new Date(search.to))
    }

    const handleShowToday = () => {
        setFetch(true)
        setScroll(true)
        setStartDate(defStartDate)
        setEndDate(defEndDate)
    }

    const handeleEditEvent = (object, eventObj) => {
        if (object.isPlan){
            if (view==='plan'){
                setObjectToEdit({...object})
            } else {
                setObjectToEdit({...eventObj, type: object.type, location: object.location, profile: object.profile, publisher: object.publisher})
            }
        } else {
            setObjectToEdit({...object})
        }        
    }

    const handleEditRow=(row, params={})=>{
        setObjectToEdit({...row})
    }

    const handlePrev = () => {
        let newstartDate = new Date(startDate)
        newstartDate.setDate(newstartDate.getDate() - 7)

        let newendDate = new Date(endDate)
        newendDate.setDate(newendDate.getDate() - 7)

        setStartDate(newstartDate)
        setEndDate(newendDate)
        setFetch(true)
    }

    const handleNext = () => {
        let newstartDate = new Date(startDate)
        newstartDate.setDate(newstartDate.getDate() + 7)

        let newendDate = new Date(endDate)
        newendDate.setDate(newendDate.getDate() + 7)

        setStartDate(newstartDate)
        setEndDate(newendDate)
        setFetch(true)
    }

    const handleRefresh = () => {
        setFetch(true)
    }

    const handleChangePage = newPage => {
        setPage(newPage)
        setFetch(true)
    };

    const handleTasks = date => {
        setTasksDate(date)
    }

    const propsToChildren = {
        handleRefresh,
        setObjectToEdit
    }

    const statusesColors = {
        "No Publish": 'white',
        "Ready": '#fff59d',
        "Published": '#a5d6a7'
    }

    const publisheColors = {
        'Mina': '#cfe2f3',
        'Suzana': '#b4a7d6',
        'Jana': '#dd7e6b',
        '':'white'
    }

    const today = new Date()
    const classes = useStyles();

    return (
        <div className="fullWidthContainer" >
            <MarketingEvent {...propsToChildren} objectToEdit={objectToEdit} isPlan={(view==='plan')}/>
            <MarketingTasks tasksDate={tasksDate} setTasksDate={setTasksDate}/>
            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 40}} elevation={1}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(view==='calendar')&&<>
                    <IconButton disabled={disabled} color='primary' style={{padding: 10}} onClick={handlePrev}>
                        <ChevronLeftIcon fontSize='small' />
                    </IconButton>
                    {startDate.getDate()}.{startDate.getMonth()+1} - {new Date(endDate).getDate()}.{new Date(endDate).getMonth()+1} 
                    <IconButton disabled={disabled} color='primary' style={{padding: 10}} onClick={handleNext}>
                        <ChevronRightIcon fontSize='small' />
                    </IconButton>
                    <Button disabled={disabled} color='primary' variant='outlined' size='small' onClick={handleShowToday}>Today</Button>
                    </>}
                    {(view === 'data')&&<Search from={startDate} to={endDate} handleSearch={handleSearch} disabled={disabled} cells={cells}/>}
                </div>
                <div>
                    {SYSTEMS.map((system) => (
                        <Button key={system} style={{marginLeft:4}} size='small' color='primary' variant={systemToShow===system?'contained':'outlined'} onClick={()=>handleChangeSystemToShow(system)}>
                            {system}
                        </Button>
                    ))}
                </div>
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                {(view==='data')?
                <JSData cells={cells} data={events} handleEditRow={handleEditRow} /*sort={sort} handleSort={handleSort}*//>:
                <>
                {dates&&<Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}></TableCell>
                            {dates.map((date) => {
                                const dateObj = new Date(date)
                                const isToday = (dateObj.getDate() === today.getDate() && dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear())

                                return (
                                    <TableCell key={date} className={clsx(classes.cell,isToday&&classes.isToday)}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>{WEEK_DAYS_SHORT[dateObj.getDay()]}{(view==='calendar')&&<> - {dateObj.getDate()}.{dateObj.getMonth()+1}</>}</div> 
                                            {(view==='calendar')&&<div style={{margin: '0 4px', cursor: 'pointer'}} onClick={()=>{handleTasks(dateObj)}}>Tasks</div>}
                                        </div>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {PUBLISH_TIMES.map((publishTime) => (
                        <Fragment key={publishTime}>
                            {PUBLISH_MINUTES.map((publishMinutes) => (
                            <TableRow key={''+publishTime+publishMinutes}>
                                <TableCell component="th" scope="row" className={classes.cell}>{publishTime}:{String(publishMinutes).padStart(2, '0')}</TableCell>
                                {dates.map((date) => {
                                    const dateAsDate = new Date(date)
                                    const weekDay = new Date(date).getDay()
                                    const eventObj = (view==='calendar') ? {
                                        system: systemToShow,
                                        dateTime: `${new Date(date).toISOString().split("T")[0]}T${String(publishTime).padStart(2, '0')}:${String(publishMinutes).padStart(2, '0')}`,
                                        date: new Date(date).toISOString().split("T")[0],
                                        timeHours: publishTime,
                                        timeMinutes: publishMinutes,
                                        status: "No Publish"
                                    } : {
                                        system: systemToShow,
                                        timeHours: publishTime,
                                        timeMinutes: publishMinutes,
                                        isPlan: true,
                                        weekDay: weekDay
                                    }

                                    const eventsInCell = events.filter(event => (event.system === systemToShow && event.date === eventObj.date && event.timeHours === eventObj.timeHours && event.timeMinutes >= eventObj.timeMinutes && event.timeMinutes <= (9 + eventObj.timeMinutes)))
                                    const eventsPlanInCell = eventsPlan.filter(event => (event.system === systemToShow && event.timeHours === eventObj.timeHours && event.weekDay === weekDay && event.timeMinutes >= eventObj.timeMinutes && event.timeMinutes <= (9 + eventObj.timeMinutes)))
                                    const showAdd = ((view==='calendar' && (eventsInCell.length>0 || eventsPlanInCell.length > 0)) || (view==='plan' && eventsPlanInCell.length > 0))

                                    const isToday = (dateAsDate.getDate() === today.getDate() && dateAsDate.getMonth() === today.getMonth() && dateAsDate.getFullYear() === today.getFullYear())
                                    const isNow = (view==='calendar' && isToday && publishTime === nowHours && publishMinutes <= nowMinutes && 9+publishMinutes >= nowMinutes)
                                    
                                    return (
                                        <TableCell key={date} {...(isNow ? { ref: nowRef }:{})} className={clsx(classes.cell, classes.cellOnList, classes.canSelect, isNow&&classes.isNow)} onClick={()=>{if(!showAdd){handeleEditEvent(eventObj);}}}>
                                            {eventsPlanInCell.map((eventInCell) => (
                                                <Fragment key={eventInCell._id}>
                                                    {!eventsInCell.find(event => (eventInCell.location && event.location === eventInCell.location))&&<div onClick={()=>{handeleEditEvent(eventInCell, eventObj)}} style={{background:`linear-gradient(90deg, ${statusesColors[eventInCell.status||'No Publish']} 80%, ${publisheColors[eventInCell.publisher||'']} 80%)`}}
                                                        className={clsx(classes.publishPost, (view==='calendar')&&classes.publishPostPlan)}>
                                                            {eventInCell.location||'Not selected'}{(eventInCell.type)&&<> - <strong>{eventInCell.type}</strong></>}
                                                    </div>}
                                                </Fragment>
                                            ))}
                                            {(view==='calendar')&&<>
                                            {eventsInCell.map((eventInCell) => (
                                                <div key={eventInCell._id} onClick={()=>{handeleEditEvent(eventInCell)}} style={{background:(eventInCell.status!=='Published')?`linear-gradient(90deg, ${statusesColors[eventInCell.status||'No Publish']} 80%, ${publisheColors[eventInCell.publisher||'']} 80%)`:`${statusesColors[eventInCell.status||'No Publish']}`}}
                                                className={clsx(classes.publishPost)}>
                                                    {eventInCell.location||'Not selected'}{(eventInCell.type)&&<> - <strong>{eventInCell.type}</strong></>}{(eventInCell.posts&&eventInCell.posts[0])&&<> - {eventInCell.posts[0].name}</>}
                                                </div>
                                            ))}
                                            </>}
                                            {showAdd&&<div className={classes.publishPost} style={{color:'blue'}} onClick={()=>{handeleEditEvent(eventObj)}}>
                                                add
                                            </div>}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>))}
                        </Fragment>))}
                    </TableBody>
                </Table>}
                </>}
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                {(view==='data')?<JSTablePagination
                    count={count}
                    rowsPerPage={100}
                    page={page}
                    onChangePage={handleChangePage}
                    disabled={disabled}
                >
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='plan'?'contained':'outlined'} onClick={()=>handleChangeView('plan')}>
                        Plan
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='calendar'?'contained':'outlined'} onClick={()=>handleChangeView('calendar')}>
                        Calendar
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='data'?'contained':'outlined'} onClick={()=>handleChangeView('data')}>
                        Data
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant='outlined' href='/marketing-posts' target='_blank'>
                        Posts
                    </Button>
                </JSTablePagination>:
                <div style={{padding: 8}}>
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='plan'?'contained':'outlined'} onClick={()=>handleChangeView('plan')}>
                        Plan
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='calendar'?'contained':'outlined'} onClick={()=>handleChangeView('calendar')}>
                        Calendar
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant={view==='data'?'contained':'outlined'} onClick={()=>handleChangeView('data')}>
                        Data
                    </Button>
                    <Button style={{marginRight:8}} size='small' color='primary' variant='outlined' href='/marketing-posts' target='_blank'>
                        Posts
                    </Button>
                </div>}
            </Paper>
        </div>
    )
}