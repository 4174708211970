import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import JSData from '../JSData';
import AdminJHApi from "../../utils/AdminJHApi"
import { FIELD_TYPES, WEEK_DAYS } from '../../utils/utils';
import { Fields } from '../Fields';

export default function MarketingTasks(props) {
    const date = props.tasksDate

    const [error, setError] = useState();
    const [tasks, setTasks] = useState([]);
    const [datesFrequencies, setDatesFrequencies] = useState({});
    const [cells, setCells] = useState([]);
    const [disabled, setDisabled] = useState();
    const [newData, setNewData] = useState({});
    const [mode, setMode] = useState();
    const [sort, setSort] = useState({});

    useEffect(() => {
        if (date){
            setMode('VIEW')
            setDisabled(true)

            AdminJHApi.getMTasks({date},(response)=>{
                setTasks((response.tasks || []).sort((a, b) => {
                    if (getSortValue(a.frequency) <= getSortValue(b.frequency)){
                        return -1
                    } else {
                        return 1
                    }
                }))
                setSort({order: 'asc', orderBy: 'frequency'})
                setCells(response.cells || [])
                setDatesFrequencies(response.datesFrequencies || {})
                setDisabled(false)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setDisabled(false)
            })
        } else {
            setTasks([])
            setCells([])
            setMode()
        }
    }, [date]);

    const handleClose = () => {
        props.setTasksDate()
    }

    const handleCancel = () => {
        setMode('VIEW')
    }

    const handleSave = e => {
        e.preventDefault();

        setDisabled(true)

        AdminJHApi.createEditMTask({newData, date},(response)=>{
            setTasks((response || []).sort((a, b) => {
                if (getSortValue(a[sort.orderBy]) <= getSortValue(b[sort.orderBy])){
                    return sort.order==='asc'?-1:1
                } else {
                    return sort.order==='asc'?1:-1
                }
            }))
            setMode('VIEW')
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const getSortValue = object => {
        if (!object){
            return 0
        }

        switch (object) {
            case 'Daily': {
                return 1;
            } case 'Weekly': {
                return 2;
            } case 'Monthly': {
                return 3;
            } default:
                return object
        }
    }

    const handleSort = newSort => {
        setTasks(tasks.sort((a, b) => {
            if (getSortValue(a[newSort.orderBy]) <= getSortValue(b[newSort.orderBy])){
                return newSort.order==='asc'?-1:1
            } else {
                return newSort.order==='asc'?1:-1
            }
        }))

        setSort(newSort)
    }

    const handleAdded = () => {
        setNewData({isPlan:true, daysWeek:[0,1,2,3,4,5,6]})
        setMode('EDIT')
    }

    const handleEditTask = (row, params={})=>{
        if (params.group === 'Done'){
            setDisabled(true)

            const selected = (row.selected || {})

            AdminJHApi.setMTask({
                taskId: row._id, 
                dateTask:datesFrequencies[row.frequency], 
                selected: {...selected, [params.cell]: !(selected[params.cell]||false)}, 
                date
            },(response)=>{
                setTasks(response || [])
                setDisabled(false)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setDisabled(false)
            })
        } else {
            setNewData({...row})
            setMode('EDIT')
        }
    }

    const handleDelete = () => {
        setError()
        setNewData({...newData, status:'Closed'})
        setMode('DELETE')
    }

    const frequencies = [
        {value: "Daily", label:"Daily"},
        {value: "Weekly", label:"Weekly"},
        {value: "Monthly", label:"Monthly"},
    ]

    const weekDays = WEEK_DAYS.map((day, index)=>{return {value:index, label:day}})

    const fields = [
        { name: 'name', label: 'Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        { name: 'frequency', label: 'Frequency', defaultValue: '', options:frequencies, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'daysWeek', label: 'Days', optionsLabels:WEEK_DAYS, defaultValue: [], options:weekDays, type: FIELD_TYPES.SELECT_MULTIPLE, sm:12, hide: ('Daily' !== getValue('frequency','')) },
        { name: 'notes', label: 'Notes', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12}, 
    ]

    /*
    dateCreated: { type: Date, default: Date.now },
        name: String,
        frequency: String, 
        notes: String,
        daysWeek: Array,
        isPlan: { type: Boolean, index: true },
        date: {String, index: true },
        fromTaskId: mongoose.Schema.Types.ObjectId
    */

    return (
        <Dialog onClose={handleClose} open={date !== undefined} fullWidth maxWidth={(mode === 'DELETE')?'xs':'lg'}>
            {(mode==='VIEW')&&<>
                {date&&<DialogTitle>Tasks {WEEK_DAYS[date.getDay()]} - {date.getDate()}.{date.getMonth()+1}</DialogTitle>}
                <DialogContent style={{padding:0}} dividers>
                    <JSData cells={cells} data={tasks} handleEditRow={handleEditTask} sort={sort} handleSort={handleSort}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleAdded} color="primary" size="small">
                        add
                    </Button>
                    <Button disabled={disabled} onClick={handleClose} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </>}
            {(mode==='EDIT'||mode==='DELETE')&&<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(mode !== 'DELETE')&&<Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>}
                    {(mode === 'DELETE')&&<Typography>Are you sure?</Typography>}
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        {(mode==='EDIT' && newData._id)&&<Button disabled={disabled} onClick={handleDelete} color="primary" size="small">
                            Delete
                        </Button>}
                    </div>
                    <div>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small">
                            {(mode==='DELETE')?'No':'Cancel'}
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            {(mode==='DELETE')?'Yes':'Save'}
                        </Button>
                    </div>
                </DialogActions>
            </form>}
        </Dialog>
    )
}