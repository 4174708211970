import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'

import { reAuthUser, loginUser, logoutUser, toggleLoginDialog, updateUserInfo } from '../redux/actions/authActions'
import LoginDialog from '../components/dialogs/LoginDialog';
import UserAvatar from './UserAvatar'
import Divider from '@material-ui/core/Divider';
//import UserDialog from '../components/dialogs/UserDialog'

import api from "../utils/HistApi";
import { showDialog, showFeedback } from '../redux/actions/MainDialog';

import ReactGA from 'react-ga4'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProfileIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SwitchIcon from '@material-ui/icons/SwapHoriz';
import LogOutIcon from 'mdi-material-ui/Logout'
import ReportIcon from '@material-ui/icons/Report';
import TemplatesIcon from '@material-ui/icons/Description';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PaymentIcon from '@material-ui/icons/Payment';
import PostJobIcon from 'mdi-material-ui/BriefcasePlus'
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { postNewJob } from '../redux/actions/selectedJob';
import { getRedirectPath } from '../utils/utils'
import { IconButton, List, Popover } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';


class UserNav extends Component {
    state = {
        anchorEl: null,
        menmuIndex: 0
    };

    constructor(props) {
        super(props);

        if (!props.isAuthed){
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get("login")){
                this.showLoginDialog()
            }
        }
        
        this.showMessage = this.showMessage.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(reAuthUser());
    }
    
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };    
    
    showLoginDialog = () => {
        const { dispatch } = this.props
        dispatch(toggleLoginDialog(true))
    };
    
    handleSetSection = section => {
        const { dispatch } = this.props
        dispatch(toggleLoginDialog(true, section))
    }

	closeLoginDialog = () => {
        const { dispatch } = this.props
        dispatch(toggleLoginDialog(false))
    };

    showMessage = (title, content) => {
        const { dispatch } = this.props
        dispatch(showDialog(title, content))
    }

    handleFeedback = () => {
        const { dispatch } = this.props
        dispatch(showFeedback())
        this.handleMenuClose();
    }

    handleLogin = (payload) => {
        this.props.dispatch(
            loginUser(payload, () => {
                //this.closeLoginDialog();
                //this.clearForm();
                /*const { from } = this.props.location.state || {
                    from: { pathname: "/profile" }
                };
                this.props.history.push(from.pathname);*/
            })
        );
    }

    handleLogOut = () => {
        const { dispatch } = this.props
        dispatch(logoutUser(()=>{
            this.setState({ anchorEl: null });
        }));
    }

    handletoProfile = () => {
        this.setState({ anchorEl: null });
        
        const { dispatch } = this.props
        dispatch(push('/profile'));
    }

    handletoSettings = () => {
        this.setState({ anchorEl: null });
        
        const { dispatch } = this.props
        dispatch(push('/settings'));
    }

    onMenuItemClicked = (location) => {    
        this.setState({ anchorEl: null });

        const { dispatch } = this.props
        dispatch(push('/' + location));
    }

    handleChangeRole = (lookingforwork) => {
        if (lookingforwork){
            ReactGA.event({category: 'User',action: 'became_jobseeker'});
        } else {
            ReactGA.event({category: 'User',action: 'became_recruiter'});
        }

        this.setState({ anchorEl: null });
        let fieldsToUpdate = {};

        fieldsToUpdate.lookingforwork = lookingforwork;

        api.saveProfile(fieldsToUpdate, (result)=>{
            const { dispatch } = this.props
            dispatch(updateUserInfo({userInfo: result.userData, applies: result.applies}));
            dispatch(push('/'));
        })
    }

    handlePostNewJob = () => {
        this.setState({ anchorEl: null });
        this.props.dispatch(postNewJob())
    }

    handleNextchangeMenu = newMenuIndex => () => {
        this.setState({ menmuIndex: newMenuIndex });
    }


    render() {
        const isJobHunt = this.props.isJobHunt || false
        const { isAuthed, userInfo, loginErrors, openDialog, isFetching, loginChecked, histogramType, section, selectedJob, pathname, isJHUser } = this.props

        const isAdmin = (isAuthed && userInfo.isAdmin)
        const isJHAdmin = (isAuthed && userInfo.isJHAdmin)
        const isPro = (isAuthed && userInfo.isPro)
        const avatarSrc = (userInfo.avatar && userInfo.avatar.url) ? userInfo.avatar.url : undefined
        const redirectPath = getRedirectPath(pathname, histogramType, selectedJob._id)

        const { anchorEl, menmuIndex } = this.state;
        const open = Boolean(anchorEl);

        const view = (window.innerWidth <= 768)?'MOBILE':'DESKTOP'

        let menus = []

        if (isJHAdmin || isAdmin){
            menus.push({label:'JobHunt', id: 0})
        }
        if (isAdmin){
            menus.push({label:'JobSwipe', id: 1})
        }
        menus.push({label:'Regular', id: 2})

        const validMenuIndex = (menmuIndex>menus.length-1) ? 0 : menmuIndex

        const menu = menus[validMenuIndex] || {}
        const menuId = menu.id

        return (
            <>
            {<React.Fragment>
                {(isAuthed) ? 
                <React.Fragment>
                    <UserAvatar onClick={this.handleMenu} 
                        firstName={userInfo.first_name} 
                        lastName={userInfo.last_name} 
                        name={userInfo.name} 
                        avatarSrc={avatarSrc} 
                        backgroundColor={userInfo.card_color} 
                        header
                        pro={isPro}/>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={this.handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        classes={{ paper: "userMenu" }}  
                    >
                        {(view==='MOBILE' && menus.length>1)&&<ListItem style={{justifyContent: 'space-between'}}>
                            <IconButton disabled={isFetching || validMenuIndex===menus.length-1} color='primary' onClick={this.handleNextchangeMenu(validMenuIndex+1)} aria-label="cancel">
                                <ChevronLeftIcon/>
                            </IconButton>
                            <IconButton disabled={isFetching || validMenuIndex===0} color='primary' onClick={this.handleNextchangeMenu(validMenuIndex-1)} aria-label="cancel">
                                <ChevronRightIcon/>
                            </IconButton>
                        </ListItem>}
                        {((isJHAdmin || isAdmin)&&(view==='DESKTOP' || menuId===0))&&<List component="nav" style={{background:"linear-gradient(#f8ebfe, #fefeff, #f8ebfe)"}}>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-users')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="JobHunt Users"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-settings')} disabled={isFetching}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="JobHunt Settings"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-reviews')} disabled={isFetching}>
                                <ListItemIcon>
                                    <RateReviewIcon/>
                                </ListItemIcon>
                                <ListItemText primary="JobHunt Reviews"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('marketing-posts')} disabled={isFetching}>
                                <ListItemIcon>
                                    <ListIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Marketing Posts"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('marketing-calendar')} disabled={isFetching}>
                                <ListItemIcon>
                                    <DateRangeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Marketing Calendar"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-payment')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Payment Link"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-statistics')} disabled={isFetching}>
                                <ListItemIcon>
                                    <EqualizerIcon />
                                </ListItemIcon>
                                <ListItemText primary="Statistics"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-publishes')} disabled={isFetching}>
                                <ListItemIcon>
                                    <TemplatesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Publish List"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('questionnaires')} disabled={isFetching}>
                                <ListItemIcon>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Questionnaires"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('leads')} disabled={isFetching}>
                                <ListItemIcon>
                                    <ContactPhoneIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Leads"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobhunt-clients-status')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Clients List"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('jobs-found')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Jobs Found"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('interviews-report')} disabled={isFetching}>
                                <ListItemIcon>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Interviews"/>
                            </ListItem>
                            {/*<ListItem button onClick={() => this.onMenuItemClicked('synamediaClients')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Synamedia"/>
                            </ListItem>
                            <ListItem button onClick={() => this.onMenuItemClicked('bflClients')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Bfl"/>
                            </ListItem>*/}
                            <ListItem button onClick={() => this.onMenuItemClicked('events/JobHunt-Webinar')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Webinars"/>
                            </ListItem>
                        </List>}
                        {(isAdmin&&(view==='DESKTOP' || menuId===1))&&<List component="nav" style={{background: 'linear-gradient(rgb(232 240 254), rgb(254, 254, 255), rgb(232 240 254))'}}>
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('reports')} disabled={isFetching}>
                                <ListItemIcon>
                                    <ReportIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reports"/>
                            </ListItem>}
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('users')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users"/>
                            </ListItem>}
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('payments')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Payments"/>
                            </ListItem>}
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('htmltemplates')} disabled={isFetching}>
                                <ListItemIcon>
                                    <TemplatesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Templates"/>
                            </ListItem>}
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('sendpushnotifications')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PhoneAndroidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Send Push"/>
                            </ListItem>}
                            {(isAdmin)&&<ListItem button onClick={() => this.onMenuItemClicked('jssettings')} disabled={isFetching}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItem>}
                        </List>}
                        {(view==='DESKTOP' || menuId===2)&&<List component="nav">
                            {(isJHUser)&&<ListItem button onClick={() => this.onMenuItemClicked('personal-journey')} disabled={isFetching}>
                                <ListItemIcon>
                                    <PermContactCalendarIcon />
                                </ListItemIcon>
                                <ListItemText primary="Personal Journey"/>
                            </ListItem>}
                            {(!isJobHunt)&&<ListItem button onClick={() => this.onMenuItemClicked('profile')} disabled={isFetching}>
                                <ListItemIcon>
                                    <ProfileIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profile"/>
                            </ListItem>}
                            {isJobHunt&&<ListItem button onClick={() => window.location.href = 'https://jobswipe.co/profile?login=true'} disabled={isFetching}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="JobSwipe"/>
                            </ListItem>}
                            {(!isJobHunt)&&<ListItem button onClick={() => this.onMenuItemClicked('settings')} disabled={isFetching}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItem>}
                            {(!isJobHunt)&&<ListItem button onClick={() => this.onMenuItemClicked('saveditems')} disabled={isFetching}>
                                <ListItemIcon>
                                    <BookmarksIcon />
                                </ListItemIcon>
                                <ListItemText primary="Saved"/>
                            </ListItem>}
                            {(!isJobHunt)&&<ListItem button onClick={() => this.onMenuItemClicked('notifications')} disabled={isFetching}>
                                <ListItemIcon>
                                    <NotificationsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Alerts"/>
                            </ListItem>}
                            {(!isJobHunt)&&<Divider/>}
                            {(isAuthed&&userInfo.lookingforwork&&!isJobHunt)&&<ListItem button onClick={() => this.handleChangeRole(false)} disabled={isFetching}>
                                <ListItemIcon>
                                    <SwitchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Switch to recruiter"/>
                            </ListItem>}
                            {(isAuthed&&!userInfo.lookingforwork&&!isJobHunt)&&<ListItem button onClick={() => this.handleChangeRole(true)} disabled={isFetching}>
                                <ListItemIcon>
                                    <SwitchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Switch to candidate"/>
                            </ListItem>}
                            {(!isJobHunt)&&<Divider/>}
                            {(!isJobHunt && loginChecked && histogramType !== 'myJobs')&&
                            <ListItem button className="postNewJob" onClick={this.handlePostNewJob} disabled={isFetching}>
                                <ListItemIcon className="icon">
                                    <PostJobIcon />
                                </ListItemIcon>
                                <ListItemText className="text" primary="Post jobs free"/>
                            </ListItem>}
                            {/*!isJobHunt&&<ListItem button onClick={() => window.location.href = isJHUser?'https://dashboard.jobhunt.co.il?login=true':'https://jobhunt.co.il'} disabled={isFetching}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Back To JobHunt"/>
                            </ListItem>*/}
                            <ListItem button onClick={() => this.handleFeedback()} disabled={isFetching}>
                                <ListItemIcon>
                                    <FeedbackIcon />
                                </ListItemIcon>
                                <ListItemText primary="Submit feedback"/>
                            </ListItem>
                            <ListItem button onClick={this.handleLogOut} disabled={isFetching}>
                                <ListItemIcon>
                                    <LogOutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Log Out"/>
                            </ListItem>
                    </List>}
                    </Popover>
                </React.Fragment> : 
                <React.Fragment>
                    <LoginDialog
                        open={openDialog && loginChecked}
                        section={section}
                        onClose={this.closeLoginDialog}
                        handleLogin={this.handleLogin}
                        handleSetSection={this.handleSetSection}
                        loginErrors={loginErrors}
                        showMessage={this.showMessage}
                        redirectPath={redirectPath}
                        />
                    <Button style={{marginLeft:15, fontSize:'0.7rem',padding:4}} onClick={this.showLoginDialog} color="inherit" variant="outlined" aria-label="Sign In">Sign In</Button>
                </React.Fragment>}
            </React.Fragment>}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo, loginErrors, openDialog, loginChecked, section } = state.authUser;
    const isFetching = state.histogramJobs.isFetching
    const histogramType = state.histogramData.histogramType
    const selectedJob = state.selectedJob.job;
    const pathname = state.router.location.pathname

    const isJHUser = (userInfo && userInfo.isJHUser)

    return {
        isAuthed, userInfo, loginErrors, openDialog, isFetching, loginChecked, histogramType, section, selectedJob, pathname, isJHUser
    }
}

export default connect(mapStateToProps)(UserNav)