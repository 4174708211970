import axios from "axios";
import { getHost } from './utils'
import { getToken } from "./AuthHelper";

const SERVER_URL = getHost();

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        if (error && error.response && error.response.data){
            onError(error.response.data)
        } else {
            onError('Something went wrong')
        }
    });
}

const getJHReviewData = (params, onSuccess, onError = ()=>{}) => {
    post('getJHReviewData', params, onSuccess, onError)
}

const saveJHReviewData = (params, onSuccess, onError = ()=>{}) => {
    post('saveJHReviewData', params, onSuccess, onError)
}

const saveVideosFromYoutube = (params, onSuccess, onError = ()=>{}) => {
    post('saveVideosFromYoutube', params, onSuccess, onError)
}

const saveReviewsFromGoogle = (params, onSuccess, onError = ()=>{}) => {
    post('saveReviewsFromGoogle', params, onSuccess, onError)
}

const getPersonalJourneys = (params, onSuccess, onError = ()=>{}) => {
    post('getPersonalJourneys', params, onSuccess, onError)
}

const getPersonalJourneysExplenations = (params, onSuccess, onError = ()=>{}) => {
    post('getPersonalJourneysExplenations', params, onSuccess, onError)
}

const updatePersonalJourneysExplenations = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneysExplenations', params, onSuccess, onError)
}

const updatePersonalJourneysEmailsToSendTest = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneysEmailsToSendTest', params, onSuccess, onError)
}

const updatePersonalJourneyStatuses = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyStatuses', params, onSuccess, onError)
}

const updateSceduledMeetingStatuses = (params, onSuccess, onError = ()=>{}) => {
    post('updateSceduledMeetingStatuses', params, onSuccess, onError)
}

const updatePersonalJourneyPlans = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyPlans', params, onSuccess, onError)
}

const updateQuestionnaireStatuses = (params, onSuccess, onError = ()=>{}) => {
    post('updateQuestionnaireStatuses', params, onSuccess, onError)
}

const updateSummaryTypes = (params, onSuccess, onError = ()=>{}) => {
    post('updateSummaryTypes', params, onSuccess, onError)
}

const updateNewsLetterEmails = (params, onSuccess, onError = ()=>{}) => {
    post('updateNewsLetterEmails', params, onSuccess, onError)
}

const getPublishesData = (params, onSuccess, onError = ()=>{}) => {
    post('getPublishesData', params, onSuccess, onError)
}

const changePublishList = (params, onSuccess, onError = ()=>{}) => {
    post('changePublishList', params, onSuccess, onError)
}

const inlayPublishList = (params, onSuccess, onError = ()=>{}) => {
    post('inlayPublishList', params, onSuccess, onError)
}

const jhPublishUpdateGlobals = (params, onSuccess, onError = ()=>{}) => {
    post('jhPublishUpdateGlobals', params, onSuccess, onError)
}

const approvePublish = (params, onSuccess, onError = ()=>{}) => {
    post('approvePublish', params, onSuccess, onError)
}

const declinePublish = (params, onSuccess, onError = ()=>{}) => {
    post('declinePublish', params, onSuccess, onError)
}

const newPersonalJourneyPublish = (params, onSuccess, onError = ()=>{}) => {
    post('newPersonalJourneyPublish', params, onSuccess, onError)
}

const pausePublish = (params, onSuccess, onError = ()=>{}) => {
    post('pausePublish', params, onSuccess, onError)
}

const simulatePublish = (params, onSuccess, onError = ()=>{}) => {
    post('simulatePublish', params, onSuccess, onError)
}

const deletePublishCell = (params, onSuccess, onError = ()=>{}) => {
    post('deletePublishCell', params, onSuccess, onError)
}

const changeAutoPutOnPublishList = (params, onSuccess, onError = ()=>{}) => {
    post('changeAutoPutOnPublishList', params, onSuccess, onError)
}

const addCommentToPesonalJourney = (params, onSuccess, onError = ()=>{}) => {
    post('addCommentToPesonalJourney', params, onSuccess, onError)
}

const editDeleteCommentToPesonalJourney = (params, onSuccess, onError = ()=>{}) => {
    post('editDeleteCommentToPesonalJourney', params, onSuccess, onError)
}

/*const editUpcomingMeeting = (params, onSuccess, onError = ()=>{}) => {
    post('editUpcomingMeeting', params, onSuccess, onError)
}*/

const updateSafetyNet = (params, onSuccess, onError = ()=>{}) => {
    post('updateSafetyNet', params, onSuccess, onError)
}

const getJHStatisticsData = (params, onSuccess, onError = ()=>{}) => {
    post('getJHStatisticsData', params, onSuccess, onError)
}

const getJobsFoundData = (params, onSuccess, onError = ()=>{}) => {
    post('getJobsFoundData', params, onSuccess, onError)
}

const getSynamediaClientsData = (params, onSuccess, onError = ()=>{}) => {
    post('getSynamediaClientsData', params, onSuccess, onError)
}

const editClientsData = (params, onSuccess, onError = ()=>{}) => {
    post('editClientsData', params, onSuccess, onError)
}

const getPersonalJourneyHistory = (params, onSuccess, onError = ()=>{}) => {
    post('getPersonalJourneyHistory', params, onSuccess, onError)
}

const getLeadsData = (params, onSuccess, onError = ()=>{}) => {
    post('getLeadsData', params, onSuccess, onError)
}

const createEditLead = (params, onSuccess, onError = ()=>{}) => {
    post('createEditLead', params, onSuccess, onError)
}

const deleteLead = (params, onSuccess, onError = ()=>{}) => {
    post('deleteLead', params, onSuccess, onError)
}

const getLastQuestionaireDate = (params, onSuccess, onError = ()=>{}) => {
    post('getLastQuestionaireDate', params, onSuccess, onError)
}

const getQuestionnairesData = (params, onSuccess, onError = ()=>{}) => {
    post('getQuestionnairesData', params, onSuccess, onError)
}

const createEditQuestionnaire = (params, onSuccess, onError = ()=>{}) => {
    post('createEditQuestionnaire', params, onSuccess, onError)
}

const setQuestionnaireContacted = (params, onSuccess, onError = ()=>{}) => {
    post('setQuestionnaireContacted', params, onSuccess, onError)
}

const setQuestionnaireField = (params, onSuccess, onError = ()=>{}) => {
    post('setQuestionnaireField', params, onSuccess, onError)
}

const setQuestionnaireStatus = (params, onSuccess, onError = ()=>{}) => {
    post('setQuestionnaireStatus', params, onSuccess, onError)
}

const getQuestionnairesStats = (params, onSuccess, onError = ()=>{}) => {
    post('getQuestionnairesStats', params, onSuccess, onError)
}

const updatePersonalJourneyDataAdmin = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyDataAdmin', params, onSuccess, onError)
}

const getInterviewsReportData = (params, onSuccess, onError = ()=>{}) => {
    post('getInterviewsReportData', params, onSuccess, onError)
}

const getInterviewsReportDataStats = (params, onSuccess, onError = ()=>{}) => {
    post('getInterviewsReportDataStats', params, onSuccess, onError)
}

const getCoverLetterChanges = (params, onSuccess, onError = () => {}) => {
    post('getCoverLetterChanges', params, onSuccess, onError)
}

const updatenewQuestionaireNotifyList = (params, onSuccess, onError = () => {}) => {
    post('updatenewQuestionaireNotifyList', params, onSuccess, onError)
}

const endSubscription = (params, onSuccess, onError = () => {}) => {
    post('endSubscription', params, onSuccess, onError)
}

const getNewsletterWhatsApp = (params, onSuccess, onError = () => {}) => {
    post('getNewsletterWhatsApp', params, onSuccess, onError)
}

const getQuestionairesStatuses = (params, onSuccess, onError = () => {}) => {
    post('getQuestionairesStatuses', params, onSuccess, onError)
}

const getCalendars = (params, onSuccess, onError = () => {}) => {
    post('getCalendars', params, onSuccess, onError)
}

const scheduleMeetingQuestionnaire = (params, onSuccess, onError = () => {}) => {
    post('scheduleMeetingQuestionnaire', params, onSuccess, onError)
}

const uploadQuestionnaireCVFile = (params, onSuccess, onError = () => {}) => {
    post('uploadQuestionnaireCVFile', params, onSuccess, onError)
}

const getMPosts =  (params, onSuccess, onError = () => {}) => {
    post('getMPosts', params, onSuccess, onError)
}

const createEditMPost =  (params, onSuccess, onError = () => {}) => {
    post('createEditMPost', params, onSuccess, onError)
}

const getMCalendar =  (params, onSuccess, onError = () => {}) => {
    post('getMCalendar', params, onSuccess, onError)
}

const findMPosts =  (params, onSuccess, onError = () => {}) => {
    post('findMPosts', params, onSuccess, onError)
}

const createEditMEvent =  (params, onSuccess, onError = () => {}) => {
    post('createEditMEvent', params, onSuccess, onError)
}

const getMPublish =  (params, onSuccess, onError = () => {}) => {
    post('getMPublish', params, onSuccess, onError)
}

const getMTasks =  (params, onSuccess, onError = () => {}) => {
    post('getMTasks', params, onSuccess, onError)
}

const createEditMTask =  (params, onSuccess, onError = () => {}) => {
    post('createEditMTask', params, onSuccess, onError)
}

const setMTask =  (params, onSuccess, onError = () => {}) => {
    post('setMTask', params, onSuccess, onError)
}

const getMSetting =  (params, onSuccess, onError = () => {}) => {
    post('getMSetting', params, onSuccess, onError)
}

const api = {
    getJHReviewData,
    saveJHReviewData,
    saveVideosFromYoutube,
    saveReviewsFromGoogle,
    getPersonalJourneys,
    getPersonalJourneysExplenations,
    updatePersonalJourneysExplenations,
    updatePersonalJourneysEmailsToSendTest,
    updatePersonalJourneyStatuses,
    updateSceduledMeetingStatuses,
    updatePersonalJourneyPlans,
    updateQuestionnaireStatuses,
    updateSummaryTypes,
    updateNewsLetterEmails,
    getPublishesData,
    changePublishList,
    jhPublishUpdateGlobals,
    inlayPublishList,
    approvePublish,
    declinePublish,
    newPersonalJourneyPublish,
    pausePublish,
    simulatePublish,
    deletePublishCell,
    changeAutoPutOnPublishList,
    addCommentToPesonalJourney,
    editDeleteCommentToPesonalJourney,
    //editUpcomingMeeting,
    updateSafetyNet,
    getJHStatisticsData,
    getJobsFoundData,
    getSynamediaClientsData,
    editClientsData,
    getPersonalJourneyHistory,
    getLeadsData,
    createEditLead,
    deleteLead,
    getLastQuestionaireDate,
    getQuestionnairesData,
    createEditQuestionnaire,
    setQuestionnaireContacted,
    setQuestionnaireField,
    setQuestionnaireStatus,
    getQuestionnairesStats,
    updatePersonalJourneyDataAdmin,
    getInterviewsReportData,
    getInterviewsReportDataStats,
    getCoverLetterChanges,
    updatenewQuestionaireNotifyList,
    endSubscription,
    getNewsletterWhatsApp,
    getQuestionairesStatuses,
    getCalendars,
    scheduleMeetingQuestionnaire,
    uploadQuestionnaireCVFile,
    getMPosts,
    createEditMPost,
    getMCalendar,
    findMPosts,
    createEditMEvent,
    getMPublish,
    getMTasks,
    createEditMTask,
    setMTask,
    getMSetting
};
export default api;